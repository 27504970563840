import React, { useState } from "react";
import { Container } from "react-bootstrap"
import "./StatsComponent.scss"
import CTALink from "../elements/CTALink"
import { parseHTML } from "../../common/utils/utils"
import ScrollAnimation from "react-animate-on-scroll"
import Animation from "../elements/Animation"
import mapimage from "../../images/desktop-home-map-static-img.jpg"
import mapimagelarge from "../../images/large-desktop-home-map-static-img.jpg"
import mapimagetab from "../../images/tablet-home-map-static-img.jpg"
import LocationMap from "../map/google/LocationMap"
import useDeviceMedia from "../../hooks/useDeviceMedia"

const StatsComponent = ({ module }) => {
  const { content, cta, stats, title } = module
  const [mapImage, setMapImage] = useState(true);
  const { isTablet,isMobile } = useDeviceMedia()
  return (
    <div className="stat-component">
      <Container>
        <div className="stat-container section-m-120">
          <Animation animateInType={"up"} className="content-section">
            <h2 className="heading">{parseHTML(title?.data?.title)}</h2>
            <p className="content">{parseHTML(content?.data?.content)}</p>
            <CTALink
              cta={cta}
              className={`button button-green-outline valuation-btn ${cta.cta_label==="Book a Valuation"?"book_val_but":""}`}
            />
          </Animation>
          {/* {stats?.length > 0 && (
            <Animation animateInType={"up"} className="stat-section">
              {stats.map(stat => (
                <>
                  <div className="stat-result">
                    <h3>{stat.stat_value}</h3>
                    <div className="stat_content">
                      <p className="stat-title">{stat?.stat_title}</p>
                      <p className="stat_description">
                        {stat?.stat_description}
                      </p>
                    </div>
                  </div>
                  <div className="horizontal-line"></div>
                </>
              ))}
            </Animation>
          )} */}
          <Animation animateInType={"up"} className="map-img-section">
            {mapImage ? 
              <>
                {!isMobile && isTablet ?
                  <img src={mapimagetab} alt="" className="bg-mapimage" onClick={() => setMapImage(false)} />
                :
                  <>
                  <img src={mapimage} alt="" className="bg-mapimage sm-desk" onClick={() => setMapImage(false)} />
                  <img src={mapimagelarge} alt="" className="bg-mapimage lg-desk" onClick={() => setMapImage(false)} />
                  </>
                }
              </>
            :
              // <LocationMap className="google-homemap" />
              <iframe
                src="https://maps.google.com/maps?width=100%&height=100%&hl=en&q=christopher hodgson whitstable estate agents&t=&z=15&ie=UTF8&iwloc=B&output=embed"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            }
          </Animation>
        </div>
      </Container>
    </div>
  )
}

export default StatsComponent
